<!-- 任务信息编辑提交页面 -->
<template>
  <div class="main">
    <div class="edit_area" v-show="!emptyState">
      <div class="view_title">
        <h3>任务提交 - {{ $route.query.brand_name }}</h3>
        <div class="inte_view">
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px"
            @click="databaseVisSign = !databaseVisSign">
            数据库管理
          </el-button>

          <span class="today">今日剩余积分：{{ integral }}</span>
          <el-tooltip effect="light">
            <div class="tip_btn" slot="content">
              <div class="tip_items">
                <span class="t_title">扣分规则：</span>
                <span>优先级：低—0；中—1；高—2；极高—3；无与伦比高—4；</span>
              </div>
              <div class="tip_items">
                <span class="t_title"
                  >积分不足时，仅可提交低优先级任务；积分将在次日零点重置。</span
                >
              </div>
            </div>
            <el-button
              type="text"
              icon="el-icon-question"
              class="icon"></el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- 任务信息编辑 -->
      <div class="info">
        <el-form
          :model="addForm"
          label-width="100px"
          class="form_main"
          :rules="rules"
          ref="taskEdit"
          :inline-message="true"
          :hide-required-asterisk="true">
          <div class="form_box">
            <el-form-item label="任务名称：" class="form_item" prop="task_name">
              <el-input
                class="f_input"
                v-model.trim="addForm.task_name"></el-input>
            </el-form-item>
            <el-form-item label="执行类型：" class="form_item">
              <el-radio-group v-model="addForm.implement_type">
                <el-radio label="1">即时</el-radio>
                <el-radio label="2" disabled>定时</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- 定时任务选项 -->
            <div class="timed_task" v-show="addForm.implement_type == '2'">
              <el-form-item label="执行日期：">
                <span style="color: #999999"
                  >Tips：日期和星期数只能选择一个！</span
                >
                <!-- 月份 -->
                <el-checkbox-group
                  v-model="addForm.taskDate.month"
                  class="tt_items"
                  @change="dateChange"
                  :disabled="addForm.weeks.length != 0">
                  <el-checkbox
                    v-for="month in $co.monthOptions"
                    :label="month.value"
                    :key="month.value"
                    class="tti_span"
                    border
                    size="mini">
                    {{ month.label }}
                  </el-checkbox>
                </el-checkbox-group>
                <!-- 日期 -->
                <el-checkbox-group
                  v-model="addForm.taskDate.date"
                  class="tt_items"
                  @change="dateChange"
                  :disabled="addForm.weeks.length != 0">
                  <el-checkbox
                    v-for="month in $co.dateOptions"
                    :label="month.value"
                    :key="month.value"
                    class="tti_span"
                    border
                    size="mini">
                    {{ month.label }}
                  </el-checkbox>
                </el-checkbox-group>
                <span style="color: #999999">或者</span>
                <!-- 星期数 -->
                <el-checkbox-group
                  v-model="addForm.weeks"
                  class="tt_items"
                  :disabled="addForm.task_time != ''">
                  <el-checkbox
                    v-for="month in $co.weeksOptions"
                    :label="month.value"
                    :key="month.value"
                    class="tti_span"
                    border
                    size="mini">
                    {{ month.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>

              <el-form-item label="执行时间：" prop="days">
                <el-checkbox-group v-model="addForm.days" class="tt_items">
                  <el-checkbox
                    v-for="month in $co.timeOptions"
                    :label="month.value"
                    :key="month.value"
                    class="tti_span"
                    border
                    size="mini">
                    {{ month.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>

            <el-form-item label="任务类型：" class="form_item">
              <el-radio-group
                v-model="addForm.task_type"
                @change="pltOrTypeChange">
                <el-radio label="1">关键词</el-radio>
                <el-radio label="2">掌柜ID</el-radio>
                <el-radio label="3">商品ID</el-radio>
                <!-- <el-radio label="4">图片搜索</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <div>
              <!-- 根据任务类型切换 -->
              <el-form-item
                :label="
                  addForm.task_type == 1
                    ? '关键词：'
                    : addForm.task_type == 2
                    ? '店铺：'
                    : addForm.task_type == 3
                    ? '商品ID：'
                    : '图片链接'
                "
                class="form_item"
                :inline-message="false"
                prop="search_key">
                <el-input
                  type="textarea"
                  placeholder="每条内容单独一行填写，请务必注意每行和最后行回车"
                  v-model="addForm.search_key"
                  :rows="9"
                  class="f_input"></el-input>
              </el-form-item>
            </div>
            <!-- 只有任务类型为关键词且所填内容为一个时，平台可多选 -->
            <!-- 选择店铺链接任务，拼多多、当当、1688不可选 -->
            <!-- 选择商品ID任务，拼多多不可选 -->
            <el-form-item label="平台：" class="form_item" prop="plt">
              <div class="plt_box">
                <el-checkbox-group
                  v-model="addForm.plt"
                  :max="pltMax"
                  @change="pltOrTypeChange">
                  <el-checkbox
                    v-for="plt in $co.pltOptions.filter((i) => !i.noEdit)"
                    :label="plt.value"
                    :key="plt.value"
                    :disabled="
                      addForm.task_type == 2 &&
                      (plt.value == 4 || plt.value == 6 || plt.value == 10)
                    "
                    style="width: 82px">
                    <!-- 不限制商品id--拼多多 -->
                    <!-- :disabled="(addForm.task_type == 2 && (plt.id == 4 || plt.id == 6 || plt.id == 10))" -->

                    <!-- 当前限制：1.选择掌柜ID，平台限制选择拼多多、当当、1688；2.选择商品ID，平台限制拼多多 -->
                    <!-- :disabled="(addForm.task_type == 2 && (plt.id == 4 || plt.id == 6 || plt.id == 10)) || ( addForm.task_type == 3 && plt.id == 4 )" -->
                    <svg class="icon-svg cust_icon" aria-hidden="true">
                      <use :xlink:href="plt.icon"></use>
                    </svg>
                    {{ plt.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>

          <div class="form_box">
            <!-- <el-form-item label="数据版本：" class="form_item">
                            <el-radio-group v-model="addForm.tidy">
                                <el-radio label="0">简单版</el-radio>
                                <el-radio label="1">详细版</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
            <el-form-item label="删除品牌词：" class="form_item">
              <!-- <el-form-item label="删除品牌词：" class="form_item long_label"> -->
              <el-radio-group v-model="addForm.del_search_key">
                <el-radio label="0">否</el-radio>
                <el-radio label="1">是</el-radio>
              </el-radio-group>
              <span style="color: red; margin-left: 15px">仅适用于京东！</span>
            </el-form-item>
            <el-form-item label="排序方式：" class="form_item">
              <el-radio-group v-model="addForm.sort">
                <el-radio label="0">综合排序</el-radio>
                <el-radio label="1">销量排序</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="需要SKU：" class="form_item">
              <!-- <el-radio-group v-model="addForm.need_sku"> -->
              <!-- <el-radio-group v-model="addForm.need_sku" :disabled="addForm.plt.some(item => item === '4')"> -->
              <el-radio-group
                v-model="addForm.need_sku"
                :disabled="addForm.plt.some((item) => item === '3')">
                <el-radio label="0">否</el-radio>
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="采集页数：" class="form_item">
              <el-input-number
                v-model="addForm.page"
                :min="1"
                :max="100"
                label="描述文字"
                size="small"></el-input-number>
            </el-form-item>
            <el-form-item label="失效时间：" class="form_item">
              <el-date-picker
                v-model="addForm.last_time"
                :clearable="false"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择失效日期时间">
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="优先级：" class="form_item">
                            <el-select v-model="addForm.level" size="mini" style="width: 120px;" disabled>
                                <el-option
                                v-for="le in $co.taskLevelOptions"
                                :key="le.value"
                                :label="le.label"
                                :value="le.value"
                                ></el-option>
                            </el-select>
                        </el-form-item> -->

            <div class="other_info">
              <h3 class="o_i_title">Tips:</h3>
              <p>
                计划"到底"将在您"确定"操作完毕后立即执行，后续您可以根据自己的需要点击“立即执行”来运行计划。
              </p>
              <p>此计划执行一次费用预估:</p>
              <p>
                (淘宝、天猫、京东、闲鱼、拼多多平台采集或关键字采集、店铺采集、搜索链接采集按
                0.05/条计算，其余采集按 0.03/条计算)
              </p>
              <p>0.05 * 44 = 2.20 元</p>
              <p>
                (以预估数据量且
                不考虑一链多款进行的估算,仅作参考,具体扣费以实际采集量为准)
              </p>
            </div>

            <div class="btn_box">
              <el-button
                type="text"
                class="fontColor_theme"
                @click="downloadTem"
                >筛查模板</el-button
              >
              <div class="up_btn">
                <el-button type="primary" size="mini">数据筛查</el-button>
                <input
                  type="file"
                  class="upload"
                  ref="refUpload"
                  @change="importHandle" />
              </div>
              <el-button
                type="primary"
                size="mini"
                @click="taskCost"
                :disabled="stopSubSign"
                >提交任务</el-button
              >
              <el-button size="mini" @click="goBack">返回列表</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 提交任务费用展示 -->
    <el-dialog :visible.sync="priceVis" title="费用明细" width="500px">
      <div v-show="completeView">
        <div slot="footer" style="text-align: center">
          <el-result icon="success" title="任务创建成功"> </el-result>
          <el-button
            type="primary"
            size="mini"
            @click="
              priceVis = false;
              completeView = false;
            "
            >继续创建</el-button
          >
          <el-button size="mini" @click="goBack">返回任务列表</el-button>
        </div>
      </div>
      <div v-show="!completeView">
        <div class="expense_details">
          <h3 class="warning_text">
            请务必提前在相关平台确认该关键词是否有数据！
          </h3>
          <p style="font-size: 16px; color: red; font-weight: bold">
            任务队列总量：{{ taskQueueInfo }}
          </p>
          <p>数据量：{{ expense_details.data_count }} 条</p>
          <p>预估费用：{{ expense_details.result }} 元</p>
          <div class="auto_include">
            <div class="is_auto clearfix">
              <p class="f-l">自动收录：</p>
              <el-switch
                class="f-l"
                v-model="addForm.need_warehousing"
                active-color="#13ce66"
                inactive-color="#ccc"
                active-value="1"
                inactive-value="0">
              </el-switch>
            </div>
            <p>
              <el-select
                v-show="addForm.need_warehousing == '1'"
                v-model="addForm.count_list"
                placeholder="选择目标数据库"
                size="mini">
                <el-option
                  v-for="item in databaseList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"></el-option>
              </el-select>
            </p>
          </div>
        </div>
        <div slot="footer" style="text-align: center">
          <el-button
            type="primary"
            size="mini"
            @click="submitHandle"
            :loading="btnLoading"
            >继续提交</el-button
          >
          <el-button size="mini" @click="priceVis = false" :loading="btnLoading"
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 商品ID检测 -->
    <el-dialog
      title="数据筛查"
      :visible.sync="gidTestVis"
      width="800px"
      custom-class="popup_style"
      :before-close="beforeC">
      <LWtableComp
        :columnsList="gtTableConfig.columnsList"
        :dataList="gtTableConfig.dataList"
        :orderNumConfig="{}">
        <el-table-column slot="is_income" label="状态" align="center">
          <template slot-scope="scope">
            <div
              :class="
                scope.row.is_income == '0'
                  ? 'fontColor_warning'
                  : scope.row.is_income == '1'
                  ? 'fontColor_success'
                  : scope.row.is_income == '3'
                  ? 'fontColor_danger'
                  : ''
              ">
              {{
                scope.row.is_income == "0"
                  ? "未收录"
                  : scope.row.is_income == "1"
                  ? "收录"
                  : scope.row.is_income == "3"
                  ? "不存在"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
      </LWtableComp>
      <div class="btn_box" slot="footer">
        <el-button size="small" type="primary" @click="addIDToEdit"
          >新建任务</el-button
        >
        <el-button size="small" @click="gidTestVis = false">关闭窗口</el-button>
      </div>
    </el-dialog>
    <!-- 数据库管理 -->
    <DatabaseCom
      :databaseList="databaseList"
      :currentBrand="Number(currentBrand)"
      :dbView="databaseVisSign"
      @getAgain="getDBList">
    </DatabaseCom>
    <!-- 空状态展示 -->
    <el-empty
      description="请先选择好需要建任务的品牌再来吧"
      v-show="emptyState"
      class="empty_box">
      <el-button type="text" @click="jumpHandle">去品牌列表</el-button>
    </el-empty>
  </div>
</template>

<script>
import DatabaseCom from "@/components/tool/databaseCom.vue";

export default {
  name: "taskInfoEdit",
  components: {
    DatabaseCom,
  },
  data() {
    let userId = this.utils.sessionGetStore("userId");
    // 商品ID验证
    let testNumber = (rule, value, callback) => {
      value = value.trim();
      if (value === "") {
        callback(new Error("商品ID未填写"));
      } else if (/[0-9]\n*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入纯数字"));
      }
    };

    let otherValidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入关键词或店铺信息"));
      } else if (value.split("\n").length > 20) {
        callback(new Error("最多填入20条内容"));
      } else {
        callback();
      }
    };

    return {
      userId,
      currentBrand: "", // 当前品牌
      emptyState: false,
      // 表单初始化模板
      initForm: {
        task_name: "", // 任务名称
        search_key: "", // 关键词 or 掌柜ID or 商品ID or 图片搜索
        implement_type: "1", // 执行类型 1--即时 2--定时 默认 1
        task_type: "1", // 任务类型默认为 1--关键词 2--掌柜ID 3--商品ID 4--图片搜索
        need_sku: "0", // 是否需要sku 0--否 1--是
        sort: "0", // 排序方式 0--综合排序 1--销量排序
        tidy: "1", // 数据版本 0--非详版 1--详版 默认 1，不显示选项
        del_search_key: "0", // 检索时删除品牌词（仅适用于京东）
        page: "1", // 页数
        last_time: this.utils.getStandardTime(
          new Date().getTime() + 3600 * 1000 * 24 * 7
        ), // 默认七天后
        level: "0", // 优先级 0--低 1--高
        task_time: "", // 任务日期 月份加日期 12月2号3号：12-02-;12-03-;
        days: [], // 时间
        weeks: [], // 周
        plt: [], //平台
        taskDate: {
          month: [],
          date: [],
        }, // 当前任务日期
        need_warehousing: "0", // 是否自动收录 0-否 1-是
        count_list: "", // 收录数据库id
      },
      addForm: {}, // 任务信息表单

      rules: {
        task_name: [
          { required: true, message: "请输入任务名", trigger: "blur" },
        ],
        search_key: [
          {
            required: true,
            message: "请输入关键词或店铺信息",
            trigger: "blur",
          },
        ],
        plt: [{ required: true, message: "请选择平台", trigger: "blur" }],
      },
      testNumber,
      otherValidate,
      btnLoading: false, // 按钮loading状态
      expense_details: {
        data_count: "",
        result: "",
      },
      priceVis: false,
      completeView: false, // 显示已完成页面
      gidTestVis: false,
      gtTableConfig: {
        columnsList: [
          {
            label: "商品ID",
            prop: "goods_id",
          },
          {
            label: "平台",
            prop: "platform",
          },
          {
            label: "SKU编号",
            prop: "sku_id",
          },
          {
            label: "SKU名称",
            prop: "sku_att_class",
          },
          {
            label: "状态",
            slotName: "is_income",
          },
        ],
        dataList: [],
      },
      integral: 0,
      databaseList: [], // 数据库
      databaseVisSign: false,
      taskQueueInfo: "",
      stopSubSign: false,
    };
  },
  computed: {
    pltMax() {
      // search_key内容变化。清空已选平台
      this.addForm.plt = [];
      // 只有任务类型为关键词且所填内容为一个时，平台可多选
      let single = false;
      if (this.addForm.task_type == 1) {
        let records = this.addForm.search_key.trim();
        if (records) {
          single = records.split("\n").length == 1;
        }
      }
      return single ? 10 : 1;
    },
  },
  watch: {
    "addForm.implement_type": function (val) {
      if (val == 2) {
        this.rules.days = [
          { required: true, message: "请选择执行时间", trigger: "change" },
        ];
      } else if (val == 1) delete this.rules.days;
    },
    "addForm.task_type": function (val) {
      if (val == 3) {
        this.rules.search_key = [
          { validator: this.testNumber, trigger: "change" },
        ];
      } else {
        this.rules.search_key = [
          { validator: this.otherValidate, trigger: "change" },
        ];
      }
    },
    // 选择拼多多平台时，只能填入关键词，且“是否sku”禁用，默认为否
    // 'addForm.plt': function(val) {
    //     if(this.addForm.plt.some(item => item === '4')) {
    //         this.addForm.need_sku = '0'
    //     }
    // }
    // 选择京东平台时，“是否sku”禁用，默认为否
    "addForm.plt": function (val) {
      if (this.addForm.plt.some((item) => item === "3")) {
        this.addForm.need_sku = "0";
      }
    },
  },
  created() {
    let r = this.$route.query.currentBrand;
    if (r) {
      this.currentBrand = r;
      this.emptyState = false;
    } else {
      // 如果没有当前品牌信息，显示空状态页面
      this.emptyState = true;
    }
    this.getDBList();
    this.resetForm();
    this.getIntegral();
    let date = new Date(new Date().setHours(24, 0, 0, 0));
    setInterval(() => {
      setTimeout(() => {
        if (new Date().getTime() > date) {
          this.getIntegral();
        }
      }, 0);
    }, 1000 * 60);
  },
  methods: {
    pltOrTypeChange() {
      if (this.addForm.plt.length > 0 && this.addForm.task_type) {
        // 当前平台只能单选 20230823
        this.$api
          .testPlatformAndType({
            plt: this.addForm.plt.join("-;"),
            task_type: this.addForm.task_type,
          })
          .then((res) => {
            // console.log(res);
            if (res.data) {
              this.$message.warning(res.msg);
            }
            this.stopSubSign = res.data != null;
          });
      }
    },
    // 获取数据库列表
    getDBList() {
      let paramObj = {
        brand_id: Number(this.currentBrand),
      };
      this.$api
        .getDatabase(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.databaseList = [...res.data.list];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取积分
    getIntegral() {
      this.$api.subTaskIntegral({ user_id: this.userId }).then((res) => {
        if (res.code === 0) {
          this.integral = res.data.count;
        }
      });
    },
    downloadTem() {
      window.location.href =
        "https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/%E6%95%B0%E6%8D%AE%E7%AD%9B%E6%9F%A5%E6%A8%A1%E6%9D%BF.xlsx";
    },
    addIDToEdit() {
      this.$confirm("将使用以上状态为不存在的ID进行提交，是否继续?")
        .then((_) => {
          this.gtTableConfig.dataList.forEach((item) => {
            if (item.is_income == "3") {
              this.addForm.search_key += item.goods_id + "\n";
            }
          });
          this.addForm.task_type = "3"; // 商品ID
          this.gidTestVis = false;
        })
        .catch((_) => {});
    },
    importHandle(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("brand_id", this.currentBrand);
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
        lock: true,
        text: "监测中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api
        .gIDTestFunc(formData)
        .then((res) => {
          if (res.code == 0) {
            this.gtTableConfig.dataList = res.data.list;
            this.gidTestVis = true;
          } else {
            this.$message.warning(res.msg);
          }
          loading.close();
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    beforeC() {
      this.$confirm("确认关闭？")
        .then((_) => done())
        .catch((_) => {});
    },
    // dataGetFunc() {

    // },
    // 任务预估费用计算
    taskCost() {
      this.$refs.taskEdit.validate((valid) => {
        if (valid) {
          this.priceVis = true;
          let records = this.utils.data_cost(
            this.addForm.plt,
            this.addForm.page,
            this.addForm.need_sku
          );
          this.expense_details = records;
          this.$api.getTaskQUeue().then((res) => {
            this.taskQueueInfo = `${res.data.count} 个`;
          });
        } else {
          this.$message.warning("内容输入有误！");
          return false;
        }
      });
    },
    // 任务信息提交
    submitHandle() {
      if (this.addForm.need_warehousing == "1" && !this.addForm.count_list) {
        this.$message.warning("未选择目标数据库");
        return;
      }
      this.btnLoading = true;
      let paramObj = {
        brand_id: this.currentBrand,
        user_id: this.userId,
      };
      Object.keys(this.addForm).forEach((key) => {
        if (this.addForm[key]) {
          if (key == "days" || key == "weeks" || key == "plt") {
            if (this.addForm[key].length > 0) {
              paramObj[key] = this.addForm[key].join("-;");
            }
          } else if (key == "search_key")
            paramObj.search_key = this.addForm.search_key
              .trim()
              .replace(/\n/g, "-;");
          else if (key != "taskDate") paramObj[key] = this.addForm[key];
        }
      });

      // console.log('参数', paramObj)
      this.$api
        .addTaskSubList(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.resetForm();
            this.utils.notificationInfo(
              "通知",
              res.msg + ",今日剩余" + res.data.count + "积分",
              "success"
            );
            this.completeView = true;
            this.getIntegral();
          } else {
            this.$message.warning(res.msg);
          }
          this.btnLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$message.error("任务创建失败");
        });
    },
    // 初始化表单
    resetForm() {
      this.addForm = JSON.parse(JSON.stringify(this.initForm));
    },
    // 任务日期改变
    dateChange() {
      // 初始化
      this.addForm.task_time = "";

      if (this.addForm.taskDate.month.length == 0) {
        this.$message.warning("未选择月份！");
        return;
      }
      if (this.addForm.taskDate.date.length == 0) {
        this.$message.warning("未选择日期！");
        return;
      }

      // console.log('date',this.addForm.taskDate.date)
      // console.log('month',this.addForm.taskDate.month)
      this.addForm.taskDate.month.forEach((m) => {
        this.addForm.taskDate.date.forEach((d) => {
          this.addForm.task_time += m + "-" + d + "-;";
        });
      });
      // console.log('任务日期',this.addForm.task_time)
    },
    goBack() {
      window.history.go(-1);
    },
    jumpHandle() {
      this.$router.push("/brand_view/brand_manage");
    },
  },
};
</script>

<style lang="scss" scoped>
.tip_btn {
  .tip_items {
    .t_title {
      font-weight: 900;
    }
  }
}
.main {
  margin-top: 5px;
  height: 100%;
  overflow-y: auto;

  .edit_area {
    .view_title {
      margin: 0 10px;
      height: 40px;
      background: #fff;
      border: 1px solid #eee;

      > h3 {
        float: left;
        line-height: 40px;
        // width: 100px;
        // text-align: center;
        font-size: 20px;
        margin-left: 50px;
      }

      .inte_view {
        float: right;
        padding: 0 20px;
        .today {
          margin-right: 10px;
        }

        .icon {
          /deep/ i {
            font-size: 16px;
            color: #9fa0a3;
          }
        }
      }
    }

    // 任务信息编辑
    .info {
      margin: 10px;
      background: #fff;
      .form_main {
        display: flex;
        justify-content: center;
      }
      .form_box {
        width: 40%;
        min-width: 500px;
        padding: 20px 10px 10px;

        .form_item {
          .f_input {
            width: 380px;
          }

          .plt_img {
            width: 15px;
            height: 15px;
          }

          .plt_box {
            // height: 180px;
            border: 1px solid #eee;
            background: #f4f9fa;
            padding: 0px 20px;
            width: 380px;

            .cust_icon {
              vertical-align: -4px;
            }
          }
        }
        .long_label {
          // 长label样式
          /deep/.el-form-item__label {
            line-height: 20px;
          }
        }

        .other_info {
          border: 1px solid #eee;
          padding: 20px;
          // .o_i_title {
          // padding-left: 20px;
          // }
          > p {
            margin-top: 5px;
          }
          > p:nth-child(4),
          > p:nth-child(5),
          > p:nth-child(6) {
            text-align: center;
          }
        }

        // 定时任务选项区域
        .timed_task {
          .tt_items {
            box-sizing: border-box;
            border: 1px solid #eee;
            padding: 10px;

            // 修改单个选择框样式
            .tti_span {
              width: 50px;
              margin: 0 5px;
              padding: 0;
              text-align: center;
              line-height: 28px;
              // 选项前面的小框
              /deep/.el-checkbox__inner {
                display: none !important;
              }
              // 选项中的字体
              /deep/.el-checkbox__label {
                padding-left: 0;
              }
            }
          }
        }

        .btn_box {
          padding: 20px 20px 10px;
          text-align: right;

          .up_btn {
            position: relative;
            display: inline-block;
            line-height: 40px;
            margin: 0 10px;
            .upload {
              width: 81px;
              height: 28px;
              position: absolute;
              left: 0;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.empty_box {
  height: 100%;
}
.expense_details {
  padding-left: 40px;
  margin-bottom: 50px;
  .warning_text {
    font-size: 18px;
    color: red;
    padding-bottom: 20px;
  }
  > p {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .auto_include {
    // padding: 10px;
    margin-top: 10px;

    .is_auto {
      > p {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    > p {
      margin-top: 10px;
    }
  }
}
</style>
